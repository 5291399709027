import React  from 'react';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import MomentUtils from '@date-io/moment';
import {ThemeProvider} from '@material-ui/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {renderRoutes} from 'react-router-config';
import { Toaster } from 'react-hot-toast';

import theme from './theme';
import routes from './routes';
import {
  ScrollReset,
  GoogleAnalytics,
  CookiesNotification
} from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './assets/scss/index.css';
import { SettingsProvider } from './contexts/SettingsContext';

const history = createBrowserHistory();

const App = () => {

  return (
    <SettingsProvider>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <ScrollReset/>
            {/*<GoogleAnalytics/>*/}
            {/*<CookiesNotification/>*/}
            <Toaster position={'bottom-center'}/>
            {renderRoutes(routes)}
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </SettingsProvider>
  );
};

export default App;
