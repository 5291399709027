import {createContext, useEffect, useContext, useState} from 'react';
import {addAuthorization} from '../utils/functions';
import {API_URL} from '../environment';
import useLocalStorage from '../utils/useLocalStorage';
import { useTranslation } from 'react-i18next'

// ----------------------------------------------------------------------
export const initialSettingState={
  webSiteName: 'Debaly Que Managment',
  avoidCustomer: false,
  locale: 'en',
  listDepartment:true
}
const initialState = {
  settings: initialSettingState
};

const SettingsContext = createContext(initialState);

function SettingsProvider({ children }) {
  const [tokenUser,setTokenUser] = useLocalStorage('token_user');
  const { t, i18n } = useTranslation()

  const onSuccessAuth = (user) => {
    setTokenUser(user)
  }
  const getSettings=() => {
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders = addAuthorization(myHeaders);
    let requestOptions = {
      method: 'GET',
      headers: myHeaders
    };
    fetch(API_URL + '/api/settings/', requestOptions)
      .then(response => response.json())
      .then(result => {
        setSettings(result)
        i18n.changeLanguage(result?.locale)
      })
      .catch(error => console.log('error', error));
  }
  const localSetting = localStorage.getItem('settings')
  useEffect(() => {
    if(tokenUser) {
      getSettings()
    }
  }, [tokenUser]);

  const [settings, setSettings] = useLocalStorage('settings', {
    avoidCustomer: false,
    webSiteName: 'Debaly Que Managment',
    locale:'en'
  });

  useEffect(() => {
    if(settings?.locale){
      i18n.changeLanguage(settings?.locale)
    }
  },[settings])

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        settings,
        onSuccessAuth,
        tokenUser,
        getSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}
const useSettings = () => useContext(SettingsContext);

export { SettingsProvider, SettingsContext};

export default useSettings;
