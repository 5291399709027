import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import LanguageEN from './locale/en.json'
import LanguageUK from './locale/uk.json'
import LanguageRU from './locale/ru.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      enUs: LanguageEN,
      uk: LanguageUK,
      ru: LanguageRU
    },
    fallbackLng: 'uk',
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
